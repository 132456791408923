.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;  
}

.landing-page__image {
  width: 100%;
  max-height: 64vh;
  position: relative;
  top: 6px;

}

.landing-page__title {
  font-size: 4rem;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  bottom: 32px;
}

.landing-page__subtitle {
  font-size: 1.5rem;
  /* margin-bottom: 2rem; */
  text-align: center;
  position: relative;
  bottom: 66px;
}

.landing-page__button {
  padding: 1rem 2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  bottom: 70px;
}

/* styling registration page */

.registration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

h1 {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  gap: 8px;
}
/* form h2 {
  text-align: center;
} */
form button {
  margin: 10px auto;
  width: 20%;
}

form button:hover {
  background-color: rgb(231, 231, 217);
  cursor: pointer;
}

form h6 {
  text-align: center;
  color: #187de2;
  font-size: 1rem;
  cursor: pointer;
}


/* styling login page */

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
  width: 100%;
}

.login-page h1 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
}

.login-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 3rem;
  border-radius: 10px;
  width: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
}

.login-page label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  /* border: 1px solid black; */
  width: 100%;
}

.login-page input {
  padding: 1rem;
  border-radius: 5px;
  border: none;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.login-page button {
  background-color: #1e90ff;
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  min-width: fit-content;
}

.login-page button:hover {
  background-color: #0077b6;
}


/* styling header */

.header {
  font-size: 2rem;
  height: 48px;
  background-color: #b7c9dc;
  /* padding: 0 15px; */
}

.header:first-child {
  cursor: pointer;
  /* width: auto; */
}

.header_links {
  float: right;
  cursor: pointer;
}

.logo{
  height: 20px;
  width: 20px;
  margin-top: 18px;
}

.header_links select {
  cursor: pointer;
}

.header_links span {
  margin-left: 30px;
  font-size: 1rem;

}

.no_underline {
  text-decoration: none;
  color: black;
}

/* styling Add cars page */

.car-form {
  position: relative;
  top: 60px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.car-form .form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.car-form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.car-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.car-form button[type="submit"] {
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.car-form button[type="submit"]:hover {
  background-color: #0077B5;
}

/* styling rendered car*/

.car-container {
  /* border: 1px solid black; */
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* gap: 30px; */
  font-family: cursive;

}

.car-data {
  /* max-height: 420px !important; */
  border: 2px solid bisque;
  border-radius: 5px;
  min-width: 30%;
  margin: 10px auto;
  /* background-color: coral; */
  box-shadow: 10px 10px 5px -5px lightblue;
  cursor: pointer;

}
.car-data:hover{
  transform: scale(1.08);
}

.car-image img {
  max-height: 180px;
  width: 100%;
}

.car-rent {
  /* background-color:#f5f5f5; */
  float: right;
}

.rent-days {
  width: 20px;
  cursor: pointer;
}

.book-car {
  height: 40px;
  /* border: 1px solid black; */
  background-color: skyblue;
  font-weight: 700;
  color: #093664;
  font-size: 1.3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;

}
.book-car:hover{
  background-color: rgb(116, 164, 183);
  transform: scaleY(1.2);
  border-radius: 2px;
}

.cart img {
  height: 30px;
  width: 30px;
  float: right;
  margin-right: 25px;
  margin-top: 10px;
}
.cart img:hover {
  transform: scale(1.2);
}

@media only screen and (min-width: 768px) {
  .car-form {
    max-width: 500px;
    margin: 0 auto;
  }



}

@media (max-width:768px) {
  .car-container {
    font-size: .7rem;
  }
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .landing-page__title {
    font-size: 3rem;
  }

  .landing-page__subtitle {
    font-size: 1rem;
  }

  .landing-page__button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
  
}


/* Media query for screens larger than 768px */
@media screen and (min-width: 768px) {
  /* .landing-page{

  }
  .landing-page__image {
    height: 70vh;
  } */
}


@media (min-width: 200px) and (max-width:767px) {
  .header{
    padding: 0;
  }
  
  .header_links span {
    margin-left: 6px;
    font-size: 1rem;
  
  }
  .header .logo{
    font-size: 1.4rem;
  }
  
}
/* @media (max-width: 552px) {
  .car-form {
    max-width: 100%;
    margin: 0 auto;
    top: 5px;
  }
} */